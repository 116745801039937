import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { get } from "lodash";
import { withFormik } from "formik";
import axios from "axios";

import Layout from "../components/Layout";

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { setSubmitting, setStatus }) => {
    try {
      await axios({
        method: "POST",
        url: process.env.GATSBY_VENDOR_FORM_URL,
        data: values,
      });
      setStatus({ success: "Thanks for your submission!" });
    } catch (error) {
      setStatus({
        error:
          "Something went wrong! Try again or contact us directly: hi@thehempsheet.com",
      });
    }

    setSubmitting(false);
  },
  displayName: "SubmitVendor",
});

const SubmitVendorPage = ({
  values,
  handleSubmit,
  handleChange,
  isSubmitting,
  status,
}) => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <h1 className="display-2 text-center">Submit a Vendor</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form noValidate method="POST" onSubmit={handleSubmit}>
                  <Form.Group controlId="vendorName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vendor Name"
                      name="Vendor Name"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder="https://..."
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control as="select" onChange={handleChange} required>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="onBehalfOfVendor">
                    <Form.Label>
                      Are you submitting on behalf of the company?
                    </Form.Label>
                    <Form.Control as="select" onChange={handleChange} required>
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Label>Reason/Extra Info</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      onChange={handleChange}
                      placeholder="I believe [vendor] would be a good fit because..."
                    />
                  </Form.Group>

                  {get(values, "onBehalfOfVendor") === "yes" && (
                    <fieldset>
                      <h2>Extra Vendor Information</h2>
                      <Form.Group controlId="phoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          onChange={handleChange}
                          placeholder="123-456-7890"
                        />
                      </Form.Group>

                      <Form.Group controlId="contactName">
                        <Form.Label>Contact Name</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          placeholder="Your Name"
                        />
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Label>Contact Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="listReports">
                        <Form.Label>
                          Do you list reports on your website?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          required
                        >
                          <option value="no">No</option>
                          <option value="some">Some Products/Strains</option>
                          <option value="partial">
                            Partial/Redacted Reports
                          </option>
                          <option value="yes">Yes</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="vendorExtra">
                        <Form.Label>Anything else?</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="4"
                          placeholder="Include any extra information you would like us to know"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </fieldset>
                  )}

                  <Form.Group>
                    {status && status.success && (
                      <Alert variant="success">{status.success}</Alert>
                    )}
                    {status && status.error && (
                      <Alert variant="danger">{status.error}</Alert>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit For Review
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default formikEnhancer(SubmitVendorPage);
